import { useState } from "react";
import { ITeamData } from "../../../channel/data-type";

export interface APSValue {
  type: 'home' | 'away',
  player: ITeamData.Player
}

interface AllPlayersSelectProps {
  homeTeamName: string
  awayTeamName: string
  homePlayers: ITeamData.Player[]
  awayPlayers: ITeamData.Player[]
  data: APSValue | null
  setData(data: APSValue | null): void
}

export function AllPlayersSelect(props: AllPlayersSelectProps) {
  function getPlayerKey(type: 'home' | 'away', player: ITeamData.Player) {
    return `${type}-${player.number}-${player.position}-${player.name}`;
  }

  return (
    <select
      className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_0px]`}
      value={props.data ? getPlayerKey(props.data.type, props.data.player) : undefined}
      onChange={e => {
        const arr = e.target.value.split('-');
        if (arr.length < 3) {
          return;
        }
        const [type, number, position] = arr;
        props.setData({
          type: type as 'home' | 'away',
          player: (type == 'home' ? props.homePlayers : props.awayPlayers).find(p => p.number == +number && p.position == position)!
        });
      }}>
      <option value={''}>Pilih Pemain</option>
      {
        props.homePlayers.map((_player: ITeamData.Player) => (
          <option 
            key={`home-${_player.number}-${_player.position}`}
            value={getPlayerKey('home', _player)}>
            { props.homeTeamName } - [{ _player.position }] { _player.number } { _player.name }
          </option>
        ))
      }
      {
        props.awayPlayers.map((_player: ITeamData.Player) => (
          <option 
            key={`away-${_player.number}-${_player.position}`}
            value={getPlayerKey('away', _player)}>
            { props.awayTeamName } - [{ _player.position }] { _player.number } { _player.name }
          </option>
        ))
      }
    </select>
  );
}
