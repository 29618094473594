import { useEffect, useRef } from "react";

interface Props {
  image: string
  width: number
  height: number
  speed?: 'slow' | 'medium' | 'fast'
}

export function WavingFlag(props: Props) {
  const flag = useRef<HTMLCanvasElement>(null);
  const ref_interval = useRef<NodeJS.Timer | null>(null);

  async function drawFlag(canvas: HTMLCanvasElement, amplitude: number) {
    canvas.width = props.width;
    canvas.height = props.height;
    const ctx = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
      if (!ctx) {
        reject(null);
      }

      let img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = function () {
        const scale = Math.min(props.width / img.width, props.height / img.height)
        const dw = scale * img.width;
        const dh = scale * img.height;
        const dx = (props.width - dw) / 2 + 2 * amplitude;
        const dy = (props.height - dh) / 2 + 2 * amplitude;
        ctx?.drawImage(img, 0, 0, img.width, img.height, dx, dy, dw - 4 * amplitude, dh - 4 * amplitude);
        resolve(null);
      };

      img.src = props.image;
      img.style.objectFit = 'contain'
      img.style.objectPosition = 'center'
    });
  }

  function waveFlag(canvas: HTMLCanvasElement, wavelength: number, amplitude: number, period: number, shading: number, squeeze: number) {
    if (!squeeze) squeeze = 0;
    if (!shading) shading = 100;
    if (!period) period = 200;
    if (!amplitude) amplitude = 10;
    if (!wavelength) wavelength = canvas.width / 10;

    const fps = 20;
    const ctx = canvas.getContext('2d');
    const w = canvas.width, h = canvas.height;
    const od = ctx?.getImageData(0, 0, w, h).data ?? [];

    if (ref_interval.current != null) {
      clearInterval(ref_interval.current);
    }

    ref_interval.current = setInterval(() => {
      const image_data = ctx?.getImageData(0, 0, w, h);
      let d = image_data?.data ?? [];
      const date = parseInt(new Date().getTime() as any)
      const now = date / (period);

      for (let y = 0; y < h; ++y) {
        let lastO = 0, shade = 0;
        const sq = (y - h / 2) * squeeze;

        for (let x = 0; x < w; ++x) {
          const px = (y * w + x) * 4;
          const pct = x / w;
          const o = Math.sin(x / wavelength - now) * amplitude * pct;
          const y2 = y + (o + sq * pct) << 0;
          const opx = (y2 * w + x) * 4;

          shade = (o - lastO) * shading;
          d[px] = od[opx] + shade;
          d[px + 1] = od[opx + 1] + shade;
          d[px + 2] = od[opx + 2] + shade;
          d[px + 3] = od[opx + 3];
          lastO = o;
        }
      }

      if (image_data) {
        ctx?.putImageData(image_data, 0, 0);
      }
    }, 1000 / fps);
  }

  useEffect(() => {
    if (typeof window === "undefined") return;

    (async () => {
      if (!flag.current) return;
      const amplitude = 10;
      await drawFlag(flag.current, amplitude);
      const speed = {
        'fast': 70,
        'medium': 150,
        'slow': 200
      }[props.speed ?? 'medium']
      waveFlag(flag.current, 20, amplitude, speed, 200, -0.1);
    })()

    return () => {
      if (ref_interval.current) {
        clearInterval(ref_interval.current);
      }
    }
  }, [typeof window, flag.current, props.image])

  return (
    <canvas ref={flag} width={props.width} height={props.height} />
  );
}
