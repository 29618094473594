import moment from "moment";

interface SBTimeViewProps {
  data: Date
  setData(data: Date): void
}

export function SBTimeView(props: SBTimeViewProps) {
  return (
    <div className={`flex flex-col gap-[12px]`}>
      <div className={``}>
        Jam Pertandingan Dimulai
      </div>
      <div className={`flex gap-[12px]`}>
        <input
          className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px] w-[300px]`}
          value={moment(props.data).format('YYYY-MM-DD HH:mm:ss')}
          placeholder={'Jam'}
          type={'datetime-local'}
          onChange={e => props.setData(moment(e.target.value).toDate())} />
      </div>
    </div>
  );
}
