import { useState } from "react";
import { ITeamData } from "../../../channel/data-type";

interface PlayerListInputProps {
  data: ITeamData.Player[]
  setData(data: ITeamData.Player[]): void
}

export function PlayerListInput(props: PlayerListInputProps) {
  function updateDataPlayer(data: ITeamData.Player, i: number) {
    props.setData([
      ...props.data.slice(0, i),
      { ...data },
      ...props.data.slice(i + 1)
    ]);
  }

  function deleteDataPlayer(i: number) {
    props.setData([
      ...props.data.slice(0, i),
      ...props.data.slice(i + 1)
    ]);
  }

  function addDataPlayer() {
    props.setData([
      ...props.data,
      {
        name: '',
        number: '' as any,
        position: ITeamData.Position.Kind.GK
      }
    ]);
  }

  return (
    <div className={`flex flex-col gap-[8px]`}>
      {
        props.data.map((player: ITeamData.Player, i: number) => (
          <div
            key={i}
            className={`flex items-center gap-[8px]`}>
            <select
              className={`w-[70px] outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_0px]`}
              value={player.position}
              onChange={e => updateDataPlayer({ ...player, position: e.target.value as any }, i)}>
              {
                Object.keys(ITeamData.Position.Kind).map(key => (
                  <option key={key} value={key}>{ key }</option>
                ))
              }
            </select>
            <input
              className={`outline-none w-[100px] rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_8px]`}
              value={player.number}
              type={'number'}
              placeholder={'Nomor'}
              onChange={e => updateDataPlayer({ ...player, number: e.target.value as any }, i)} />
            <input
              className={`flex-1 outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
              value={player.name}
              placeholder={'Nama Pemain'}
              onChange={e => updateDataPlayer({ ...player, name: e.target.value as any }, i)} />
            <button 
              onClick={() => deleteDataPlayer(i)}
              className={`border border-px border-red-400 text-red-400 rounded-[4px] p-[5px_12px] text-[14px]`}>
              Hapus
            </button>
          </div>
        ))
      }
      <button 
        onClick={() => addDataPlayer()}
        className={`border border-px border-sky-400 text-sky-400 rounded-[4px] p-[5px_12px] text-[14px]`}>
        Tambah Pemain
      </button>
    </div>
  );
}
