import { ITeamData } from "../../../channel/data-type";
import { TeamDataInput } from "./TeamDataInput";

interface TeamConfigurationProps {
  data: ITeamData.Match
  setData(data: ITeamData.Match): void
}

export function TeamConfiguration(props: TeamConfigurationProps) {
  return (
    <div className={`flex gap-[24px]`}>
      <div className={`flex-1`}>
        <TeamDataInput
          label={`Home`}
          data={props.data.home}
          setData={home => props.setData({ ...props.data, home })} />
      </div>
      <div className={`flex-1`}>
        <TeamDataInput
          label={`Away`}
          data={props.data.away}
          setData={away => props.setData({ ...props.data, away })} />
      </div>
    </div>
  );
}
