import { useContext, useEffect, useState } from "react";
import { DataChannelGoal, ITeamData } from "../../channel/data-type";
import { Label } from "../general/Label";
import { LocalDBContext } from "../../channel/LocalDB";
import { AllPlayersSelect, APSValue } from "../general/input/AllPlayersSelect";

interface SubpageGoalProps {}

export function SubpageGoal(props: SubpageGoalProps) {
  const { db, updateGoal } = useContext(LocalDBContext);
  const [player, setPlayer] = useState<APSValue | null>(null);

  function post() {
    new BroadcastChannel('default').postMessage({
      type: 'goal',
      data: db.goal
    });
  }

  useEffect(() => {
    if (!player) {
      return;
    }
    updateGoal({
      player_name: player.player.name,
      player_number: player.player.number,
      team_logo: player.type === 'home' ? db.match.home.logo : db.match.away.logo
    })
  }, [player]);

  return (
    <div className={`flex flex-col gap-[12px] p-[24px]`}>
      <Label label={'Pilih Pemain'}>
        <AllPlayersSelect
          data={player}
          setData={setPlayer}
          awayTeamName={db.match.away.name}
          awayPlayers={db.match.away.players}
          homeTeamName={db.match.home.name}
          homePlayers={db.match.home.players} />
      </Label>
      <button 
        className={`self-start bg-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={post}>
        Kirim
      </button>
    </div>
  );
}
