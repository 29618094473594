import { useContext } from "react";
import { LocalDBContext } from "../../channel/LocalDB";

interface SubpageIdleProps {}

export function SubpageIdle(props: SubpageIdleProps) {
  const { db, updateIdle } = useContext(LocalDBContext);

  function post() {
    new BroadcastChannel('default').postMessage({
      type: 'idle',
      data: db.idle
    });
  }

  return (
    <div className={`flex flex-col gap-[12px] p-[24px]`}>
      <input
        className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
        value={db.idle.message1}
        placeholder={'Pesan 1'}
        onChange={e => updateIdle({ ...db.idle, message1: e.target.value })} />
      <input
        className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
        value={db.idle.message2}
        placeholder={'Pesan 2'}
        onChange={e => updateIdle({ ...db.idle, message2: e.target.value })} />
      <button 
        className={`self-start bg-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={post}>
        Kirim
      </button>
    </div>
  );
}
