import { DataChannelGoal } from "../../channel/data-type"
import { WavingFlag } from "../general/WavingFlag"

interface Props {
  data: DataChannelGoal
}

export default function Goal(props: Props) {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex text-white font-extrabold font-londrina text-[400px] leading-none goal">
        <span className="goal-bounce">G</span>
        <span className="goal-bounce">O</span>
        <span className="goal-bounce">A</span>
        <span className="goal-bounce">L</span>
        <span className="goal-bounce">!</span>
        <span className="goal-bounce">!</span>
        <span className="goal-bounce">!</span>
        <span className="goal-bounce">!</span>
      </div>
      <div className="flex flex-row justify-around items-center">
        <WavingFlag speed={'fast'} image={props.data.data.team_logo} width={250} height={250} />
        <p className="text-white text-[64px] font-bold">{props.data.data.player_name}</p>
        <div className="flex relative items-center justify-center h-[200px] min-w-[200px] w-[200px]">
          <img src="/images/t-shirt.png" alt="" className="object-contain" />
          <p className="absolute text-[64px] font-bold z-50">{props.data.data.player_number}</p>
        </div>
      </div>
    </div>
  )
}
