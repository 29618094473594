import { table } from "console"
import { useEffect, useState } from "react"

export interface CountdownParam {
  start?: number
  interval: number
  onTick?(): void
  pause?: boolean
}

export function useCountdown(param: CountdownParam) {
  const [counter, setCounter] = useState<number>(param.start ?? 0);

  function reset() {
    setCounter(param.start ?? 0);
  }

  useEffect(() => {
    return () => {
      if (param.pause) {
        return;
      }
    };
  }, [param.pause]);

  useEffect(() => {
    setCounter(param.start ?? 0);
  }, [param.start])

  useEffect(() => {
    const x = setTimeout(() => {
      setCounter(counter + 1);
    }, param.interval);

    return () => {
      clearInterval(x);
    };
  }, [counter, param.start]);

  return { counter, reset };
}
