import { DataChannelIdle } from "../../channel/data-type"
import { WavingFlag } from "../general/WavingFlag"

interface Props {
  data: DataChannelIdle
}

export default function Home(props: Props) {
  return (
    <div className="flex flex-row gap-[100px]">
      <WavingFlag speed={'slow'} image={'/images/malut.png'} width={200} height={350} />
      <div className="flex flex-col">
        <p className="text-[90px] font-extralight text-white">{props.data.data.message1}</p>
        <p className="text-[140px] font-bold text-white">{props.data.data.message2}</p>
      </div>
    </div>
  )
}
