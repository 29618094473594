import { useEffect } from "react";
import Home from "../index-component/Home";
import { DataChannel } from "../../channel/data-type";
import { useCountdown } from "../../hooks/useCountdown";
import { WavingFlag } from "../general/WavingFlag";

interface TestingPageProps {}

export function TestingPage(props: TestingPageProps) {
  return (
    <div className="">
      {/* <WavingFlag image="" /> */}
    </div>
  );
}