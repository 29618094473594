import { DataChannelCard } from "../../channel/data-type"

interface Props {
  data: DataChannelCard
}

export default function Card(props: Props) {
  return (
    <div className="flex flex-row w-full justify-between items-center gap-20">
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-10">
          <div className="flex relative items-center justify-center h-[200px] min-w-[200px] w-[200px]">
            <img src="/images/t-shirt.png" alt="" className="object-contain" />
            <p className="absolute text-[64px] font-bold z-50">{props.data.data.player_number}</p>
          </div>
          <div className={`${props.data.data.type === "red" ? "bg-[#E20C0B]" : "bg-[#E7BB19]"} h-[163px] w-[140px] rounded-xl ml-4 animation-scale`}/>
        </div>
        <p className="text-white text-[95px] font-bold">{props.data.data.player_name}</p>
      </div>
      <img src={props.data.data.team_logo} alt="" className="h-[400px] min-w-[400px] w-[400px] object-contain" />
    </div>
  )
}
