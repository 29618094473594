import { useEffect, useRef, useState } from "react";
import { TabHeader } from "../TabHeader";
import { io, Socket } from "socket.io-client";
import { SubpageIdle } from "../tabs/SubpageIdle";
import { SubpageGoal } from "../tabs/SubpageGoal";
import { SubpageIklan } from "../tabs/SubpageIklan";
import { SubpageKartu } from "../tabs/SubpageKartu";
import { SubpageSubstitusi } from "../tabs/SubpageSubstitusi";
import { SubpageScoreBoard } from "../tabs/SubpageScoreBoard";
import { LocalDBContextProvider } from "../../channel/LocalDB";
import { SubpageTeamConfig } from "../tabs/SubpageTeamConfig";

interface ControllerPageProps {}

export function ControllerPage(props: ControllerPageProps) {
  const socket = useRef<Socket>();
  const [socket_instance, setSocketIntance] = useState<Socket>();
  const [tab, setTab] = useState<string>('Idle');

  useEffect(() => {
    socket.current = io('https://socket.kie-raha.graf.run');
    socket.current.on('connect', () => {
      setSocketIntance(socket.current);
    });
    
    socket.current.on('idle', (data: any) => {
      console.log('receive idle message', data);
    });
    return () => {
      socket.current?.disconnect();
    };
  }, []);

  return (
    <LocalDBContextProvider>
      <div className={`relative`}>
        <div className={`sticky top-0 z-[99]`}>
          <TabHeader
            tabs={[
              'Idle',
              'Konfigurasi Tim',
              'Score Board',
              'Goal',
              'Kartu',
              'Substitusi',
              'Iklan'
            ]}
            selectedTab={tab}
            onTabSelected={setTab} />
        </div>
        <div className={`flex flex-col`}>
          { tab === 'Idle' && <SubpageIdle /> }
          { tab === 'Konfigurasi Tim' && <SubpageTeamConfig /> }
          { tab === 'Goal' && <SubpageGoal /> }
          { tab === 'Iklan' && <SubpageIklan /> }
          { tab === 'Kartu' && <SubpageKartu /> }
          { tab === 'Substitusi' && <SubpageSubstitusi /> }
          { tab === 'Score Board' && <SubpageScoreBoard /> }
        </div>
      </div>
    </LocalDBContextProvider>
  );
}
