import React, { Fragment } from 'react';
import './App.css';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { IndexPage } from './components/pages/IndexPage';
import { ControllerPage } from './components/pages/ControllerPage';
import { TestingPage } from './components/pages/TestingPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<IndexPage />} />
        <Route path='/control' element={<ControllerPage />} />
        <Route path='/test' element={<TestingPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
