interface TabHeaderProps {
  tabs: string[]
  selectedTab: string
  onTabSelected(tab: string): void
}

export function TabHeader(props: TabHeaderProps) {
  return (
    <div className={`flex items-center overflow-x-auto`}>
      {
        props.tabs.map((tab: string, key: number) => (
          <div
            onClick={() => props.onTabSelected(tab)} 
            className={`whitespace-nowrap font-bold text-[18px] flex-1 bg-sky-50 p-[18px] text-center transition transition-all hover:bg-sky-200 cursor-pointer ${props.selectedTab == tab ? '!bg-sky-300' : ''}`}
            key={key}>
            { tab }
          </div>
        ))
      }
    </div>
  );
}
