import { useContext, useState } from "react";
import { DataChannelScoreBoard } from "../../channel/data-type";
import { SBTimeView } from "./score-board/SBTimeView";
import { SBTeamView } from "./score-board/SBTeamView";
import { LocalDBContext } from "../../channel/LocalDB";

interface SubpageScoreBoardProps {}

export function SubpageScoreBoard(props: SubpageScoreBoardProps) {
  const { db, updateScoreBoard } = useContext(LocalDBContext);

  function post() {
    new BroadcastChannel('default').postMessage({
      type: 'score-board',
      data: db.score_board
    });
  }

  return (
    <div className={`flex flex-col gap-[12px] p-[24px]`}>
      <SBTimeView 
        data={db.score_board.time_start}
        setData={time_start => updateScoreBoard({ ...db.score_board, time_start })} />
      <div className={`flex gap-[24px]`}>
        <div className={`flex-1 flex flex-col gap-[12px]`}>
          <SBTeamView 
            label={'Home'}
            data={db.score_board.home}
            setData={home => updateScoreBoard({ ...db.score_board, home })} />
        </div>
        <div className={`flex-1 flex flex-col gap-[12px]`}>
          <SBTeamView
            label={'Away'}
            data={db.score_board.away}
            setData={away => updateScoreBoard({ ...db.score_board, away })} />
        </div>
      </div>
      <button 
        className={`self-start bg-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={post}>
        Kirim
      </button>
    </div>
  );
}
