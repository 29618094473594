import { useEffect, useState } from "react";
import Home from "../index-component/Home";
import { DataChannel } from "../../channel/data-type";
import Scoreboard from "../index-component/Scoreboard";
import Goal from "../index-component/Goal";
import Card from "../index-component/Card";

interface IndexPageProps {}

export function IndexPage(props: IndexPageProps) {
  const [data, setData] = useState<DataChannel | null>(null)

  console.log('render');
  const listener = (ev: MessageEvent<DataChannel>) => {
    setData(ev.data)
  };

  useEffect(() => {
    const bc = new BroadcastChannel('default');
    bc.addEventListener("message", listener);

    return () => {
      bc.removeEventListener("message", listener);
    }
  }, []);

  return (
    <div className="w-full h-screen flex justify-center items-center bg-[url('../public/images/bg.png')] bg-cover font-inter px-[60px]">
      {data?.type === 'idle' && <Home data={data} />}
      {data?.type === 'score-board' && <Scoreboard data={data} />}
      {data?.type === 'goal' && <Goal data={data} />}
      {data?.type === 'card' && <Card data={data} />}
    </div>
  );
}
