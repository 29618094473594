import { useState } from "react";
import { InputImage } from "../../general/input/InputImage";
import { Label } from "../../general/Label";
import { PlayerListInput } from "./PlayerListInput";
import { ITeamData } from "../../../channel/data-type";

interface TeamDataInputProps {
  label: string
  data: ITeamData.Team
  setData(data: ITeamData.Team): void
}

export function TeamDataInput(props: TeamDataInputProps) {
  return (
    <div className={`flex flex-col gap-[12px]`}>
      <div className={`flex gap-[12px]`}>
        <Label label={`Logo`}>
          <InputImage
            value={props.data.logo}
            onChange={e => props.setData({ ...props.data, logo: e.target.value})} />
        </Label>
        <Label 
          className={`flex-1`}
          label={props.label}>
          <input
            className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
            value={props.data.name}
            onChange={e => props.setData({ ...props.data, name: e.target.value })}
            placeholder={'Nama Tim'} />
        </Label>
      </div>
      <Label label={`Daftar Pemain`}>
        <PlayerListInput
          data={props.data.players}
          setData={(players: ITeamData.Player[]) => props.setData({ ...props.data, players })} />
      </Label>
    </div>
  );
}
