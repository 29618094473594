import moment from "moment"
import { DataChannelScoreBoard } from "../../channel/data-type"
import { useCountdown } from "../../hooks/useCountdown"
import { WavingFlag } from "../general/WavingFlag"

interface Props {
  data: DataChannelScoreBoard
}

export default function Scoreboard(props: Props) {
  const startSecond = moment().diff(moment(props.data.data.time_start), 'seconds') 

  const { counter } = useCountdown({
    interval: 1000,
    start: startSecond
  })

  return (
    <div className="flex flex-col w-full items-center justify-around overflow-hidden">

      <div className="flex flex-row w-full justify-between">
        <p className="text-[64px] text-white font-bold text-left">{props.data.data.home.team_name}</p>
        <p className="text-[64px] text-white font-bold text-left">{props.data.data.away.team_name}</p>
      </div>

      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-row flex-1 items-center justify-between gap-20 my-16">
          <WavingFlag image={props.data.data.home.logo} width={350} height={350} />
          <p className="text-gray-50 font-bold text-[350px] leading-none">{props.data.data.home.goal}</p>
        </div>

        <p className="text-[#CECECE] font-bold text-[350px] leading-none text-center px-[40px]">-</p>

        <div className="flex flex-row flex-1 items-center justify-between gap-20 my-16">
          <p className="text-gray-50 font-bold text-[350px] leading-none">{props.data.data.away.goal}</p>
          <WavingFlag image={props.data.data.away.logo} width={350} height={350} />
        </div>
      </div>

      <div className="flex flex-row w-full justify-between">
        <div className="flex-1">
          { props.data.data.home.list_event.map((item, index) => {
            return (
              item.type === 'red-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-start mb-5">
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E20C0B] -rotate-12 ml-4"/>
                  <p className="text-[45px] text-[#E20C0B]">{item.minutes + `" ` + item.name}</p>
                </div>
              : item.type === 'yellow-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-start mb-5">
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E7BB19] -rotate-12 ml-4"/>
                  <p className="text-[45px] text-[#E7BB19]">{item.minutes + `" ` + item.name}</p>
                </div>
              :
                <div key={index} className="flex flex-row gap-6 items-center justify-start mb-5">
                  <img src="/images/ball.svg" alt="" />
                  <p className="text-[45px] font-bold text-white">{item.minutes + `" ` + item.name}</p>
                </div> 
            )
          })}
        </div>

        <p className="text-gray-50 font-bold text-[150px] text-center leading-none -mt-10">
          {String(Math.floor(counter / 60)).padStart(2, '0') + ":" + String(counter % 60).padStart(2, '0')}
        </p>

        <div className="flex-1">
          { props.data.data.away.list_event.map((item, index) => {
            return (
              item.type === 'red-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-end mb-5">
                  <p className="text-[45px] text-[#E20C0B]">{item.minutes + `" ` + item.name}</p>
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E20C0B] -rotate-12 mr-4"/>
                </div>
              : item.type === 'yellow-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-end mb-5">
                  <p className="text-[45px] text-[#E7BB19]">{item.minutes + `" ` + item.name}</p>
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E7BB19] -rotate-12 mr-4"/>
                </div>
              :
                <div key={index} className="flex flex-row gap-6 items-center justify-end mb-5">
                  <p className="text-[45px] font-bold text-white">{item.minutes + `" ` + item.name}</p>
                  <img src="/images/ball.svg" alt="" />
                </div>
            )
          })}
        </div>
      </div>

    </div>
  )
}
