import { useContext, useEffect, useState } from "react";
import { DataChannelCard } from "../../channel/data-type";
import { Label } from "../general/Label";
import { LocalDBContext } from "../../channel/LocalDB";
import { AllPlayersSelect, APSValue } from "../general/input/AllPlayersSelect";

interface SubpageKartuProps {}

export function SubpageKartu(props: SubpageKartuProps) {
  const { db, updateCard } = useContext(LocalDBContext);
  const [card_type, setCardType] = useState<'yellow' | 'red'>('yellow');
  const [player, setPlayer] = useState<APSValue | null>(null);

  function post() {
    new BroadcastChannel('default').postMessage({
      type: 'card',
      data: db.card
    });
  }

  useEffect(() => {
    if (!player) {
      return;
    }
    updateCard({
      type: card_type,
      player_name: player.player.name,
      player_number: player.player.number,
      team_logo: player.type === 'home' ? db.match.home.logo : db.match.away.logo
    })
  }, [player]);

  return (
    <div className={`flex flex-col gap-[12px] p-[24px]`}>
      <Label label={'Kartu'}>
        <select
          className={`w-[120px] outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_0px]`}
          value={card_type}
          onChange={e => setCardType(e.target.value as any)}>
          <option value={'yellow'}>Kuning</option>
          <option value={'red'}>Merah</option>
        </select>
      </Label>
      <Label label={'Pilih Pemain'}>
        <AllPlayersSelect
          data={player}
          setData={setPlayer}
          awayTeamName={db.match.away.name}
          awayPlayers={db.match.away.players}
          homeTeamName={db.match.home.name}
          homePlayers={db.match.home.players} />
      </Label>
      <button 
        className={`self-start bg-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={post}>
        Kirim
      </button>
    </div>
  );
}
