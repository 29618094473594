import { useContext } from "react";
import { LocalDBContext } from "../../channel/LocalDB";
import { TeamConfiguration } from "./team-config/TeamConfiguration";

interface SubpageTeamConfigProps {}

export function SubpageTeamConfig(props: SubpageTeamConfigProps) {
  const { db, updateMatch } = useContext(LocalDBContext);

  function post() {
    new BroadcastChannel('default').postMessage({
      type: 'idle',
      data: db.idle
    });
  }

  return (
    <div className={`flex flex-col gap-[12px] p-[24px]`}>
      <TeamConfiguration
        data={db.match}
        setData={updateMatch} />
    </div>
  );
}
