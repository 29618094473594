import { HTMLAttributes } from "react";

interface LabelProps extends HTMLAttributes<HTMLDivElement> {
  label: string
}

export function Label(props: LabelProps) {
  return (
    <div 
      {...props}
      className={`${props.className} flex flex-col gap-[4px]`}>
      <div className={`font-medium`}>
        { props.label }
      </div>
      { props.children }
    </div>
  );
}
