export interface ScoreBoardTeamEvent {
  type: 'goal' | 'yellow-card' | 'red-card'
  minutes: number
  name: string
}

export interface ScoreBoardTeamData {
  goal: number
  logo: string
  team_name: string
  list_event: ScoreBoardTeamEvent[]
}

export namespace ITeamData {
  export namespace Position {
    export enum Kind {GK = 'GK',
      DF = 'DF',
      CB = 'CB',
      LB = 'LB',
      RB = 'RB',
      SW = 'SW',
      MF = 'MF',
      CDM = 'CDM',
      CM = 'CM',
      DM = 'DM',
      AM = 'AM',
      LM = 'LM',
      RM = 'RM',
      OM = 'OM',
      FW = 'FW',
      ST = 'ST',
      CF = 'CF',
      LW = 'LW',
      RW = 'RW',
    }

    export interface Type {
      name: Kind
      order: number
      description: string
    }

    export const GK: Type = {
      name: Kind.GK,
      order: 0,
      description: 'Goalkeeper'
    }
    export const DF: Type = {
      name: Kind.DF,
      order: 1,
      description: 'Defender'
    }
    export const CB: Type = {
      name: Kind.CB,
      order: 1,
      description: 'Center-back'
    }
    export const LB: Type = {
      name: Kind.LB,
      order: 0,
      description: 'Left-back'
    }
    export const RB: Type = {
      name: Kind.RB,
      order: 0,
      description: 'Right-back'
    }
    export const SW: Type = {
      name: Kind.SW,
      order: 0,
      description: 'Sweeper'
    }
    export const MF: Type = {
      name: Kind.MF,
      order: 0,
      description: 'Midfielder'
    }
    export const CDM: Type = {
      name: Kind.CDM,
      order: 0,
      description: 'Central defensive midfielder'
    }
    export const CM: Type = {
      name: Kind.CM,
      order: 0,
      description: 'Central midfielder'
    }
    export const DM: Type = {
      name: Kind.DM,
      order: 0,
      description: 'Defensive midfielder'
    }
    export const AM: Type = {
      name: Kind.AM,
      order: 0,
      description: 'Attacking midfielder'
    }
    export const LM: Type = {
      name: Kind.LM,
      order: 0,
      description: 'Left midfielder'
    }
    export const RM: Type = {
      name: Kind.RM,
      order: 0,
      description: 'Right midfielder'
    }
    export const OM: Type = {
      name: Kind.OM,
      order: 0,
      description: 'Offensive midfielder'
    }
    export const FW: Type = {
      name: Kind.FW,
      order: 0,
      description: 'Forward'
    }
    export const ST: Type = {
      name: Kind.ST,
      order: 0,
      description: 'Striker'
    }
    export const CF: Type = {
      name: Kind.CF,
      order: 0,
      description: 'Center-forward'
    }
    export const LW: Type = {
      name: Kind.LW,
      order: 0,
      description: 'Left winger'
    }
    export const RW: Type = {
      name: Kind.RW,
      order: 0,
      description: 'Right winger'
    }
  }

  export interface Player {
    name: string
    number: number
    position: Position.Kind
  }

  export interface Team {
    name: string
    logo: string
    players: Player[]
  }

  export interface Match {
    home: Team
    away: Team
  }
}

export namespace IDataChannel {
  export interface Idle {
    message1: string
    message2: string
  }

  export interface Goal {
    player_name: string
    player_number: number
    team_logo: string
  }

  export interface ScoreBoard {
    time_start: Date
    home: ScoreBoardTeamData
    away: ScoreBoardTeamData
  }

  export interface Card {
    type: 'yellow' | 'red'
    player_name: string
    player_number: number
    team_logo: string
  }
}

export interface DataChannelIdle {
  type: 'idle'
  data: IDataChannel.Idle
}

export interface DataChannelGoal {
  type: 'goal'
  data: IDataChannel.Goal
}

export interface DataChannelScoreBoard {
  type: 'score-board'
  data: IDataChannel.ScoreBoard
}

export interface DataChannelCard {
  type: 'card'
  data: IDataChannel.Card
}

export type DataChannel = DataChannelIdle | DataChannelGoal | DataChannelScoreBoard | DataChannelCard;
